import React, { FC } from 'react';
import Toast from 'react-bootstrap/Toast';
import { dismissNotification } from './notification.slice';
import { useTypedDispatch } from '../../state/store';
import './style.scss'

export type NotificationTypes =
    | 'success'
    | 'warning'
    | 'error'

export interface INotificationItem {
    id: string;
    title: string;
    message: string;
    delay?: number;
    type: NotificationTypes;
}

type NoticationProps = {
    notification: INotificationItem;
};

export const NotificationItem: FC<NoticationProps> = ({
    notification: { id, title, message, delay = 15000, type = 'error' },
}) => {
    const dispatch = useTypedDispatch();

    return (
        <Toast
            key={id}
            autohide
            animation
            show={true}
            delay={delay}
            onClose={() => dispatch(dismissNotification(id))}
            className={`notif-container bgd-${type.toLowerCase()}`}
        >
            <Toast.Header
                className="notif-header"
                closeButton={false}
            >
                <strong className="mr-auto"> {title} </strong>
            </Toast.Header>
            <Toast.Body
                className='notif-body'
            >
                {message}
            </Toast.Body>
        </Toast>
    );
};
